import styled from 'styled-components';
import { HashLink } from "../../../../src/components/link";
import hobbyInfraImg from "../../../../src/images/hobby-infra.png";
import prodInfraImg from "../../../../src/images/prod-infra.png";
import { StyledImage } from "../../../../src/components/posts/prod-infra-migration";
import * as React from 'react';
export default {
  styled,
  HashLink,
  hobbyInfraImg,
  prodInfraImg,
  StyledImage,
  React
};